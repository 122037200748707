import React, { Component, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import Sidepanel from '../Sidepanel/Sidepanel';
import { Button, Image, Dropdown, Icon, TextArea, Form, Header, Segment, Checkbox, Confirm, Input } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import styles from './ActionDetailPanel.module.scss';
import Status from '../Status/Status';
import moment from 'moment';
import { ApiActionObject, ApiGoogleDriveDocumentObject } from '../../api/actions';
import api, { dateFormat } from '../../api/api';
import { ApiUserObject } from '../../api/admin/users';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import Editor from '../Editor/Editor';
import UserPicker from '../TogglePicker/UserPicker';
import DatePicker from '../TogglePicker/DatePicker';
import DocumentLink from '../DocumentLink/DocumentLink';
import AccountLink from '../AccountLink/AccountLink';
import { ApiAccountObject } from '../../api/admin/accounts';
import { ApiNeedObject } from '../../api/needs';
import { find, omit } from 'lodash';
import socket from '../../lib/socket';

interface OwnProps {
  open?: boolean;
  onClose?: () => void;
  action?: number,
}

interface StateProps {
  accounts: Partial<ApiAccountObject>[];
  users: { [key: number]: ApiUserObject };
  authAvatar: string;
  currentUserId: number;
}

type Props = OwnProps & StateProps;

interface PanelState {
  action?: ApiActionObject,
  duplicates: number[],
  taFocus: boolean,
  tacFocus: boolean,
  comment: string,
  assign: boolean,
  logs?: any[],
  logs_page: number,
  duplicate: boolean,
  editTitle: boolean,
  changeDeadline: boolean;
  confirmDelete: boolean,
  accountFilter: string,
  needs: ApiNeedObject[],
  editNeed: boolean,
  toggleAccounts: boolean,
}

class ActionPanel extends Component<Props, PanelState> {
  assigneeRef: any;
  assigneeToggle: any;
  deadlineRef: any;
  deadlinePicker: any;
  deadlineTimeout?: number;
  descriptionEditor: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      action: undefined,
      taFocus: false,
      tacFocus: false,
      duplicates: [],
      comment: '',
      assign: false,
      logs: [],
      logs_page: 1,
      duplicate: false,
      editTitle: false,
      changeDeadline: false,
      confirmDelete: false,
      accountFilter: '',
      editNeed: false,
      needs: [],
      toggleAccounts: false,
    };
  }

  componentWillReceiveProps = (props: any) => {
    if (props.action !== this.props.action && props.action > 0) {
      api.showAction(props.action, [
        'squad',
        'chapter',
        'need',
        'accounts',
        'documents'
      ]).then((res) => {
        this.setState({
          action: res,
        });

        this.fetchLogs();
      });
    }
  }

  fetchLogs = () => {
    const { action } = this.state;
    
    if (action) {// @TODO: paginering
      api.listActionLogs(action.id).then((res) => {
        if (!res) return;
        this.setState({
          logs: res.data,
        });
      });
    }
  }
  
  toggleEditNeed = async (needId?: number) => {
    if (this.state.editNeed) {
      const { action } = this.state;
      this.patchAction({ need_id: needId });
      if (action) {
        const need = find(this.state.needs, { id: needId });
        action.need = need;

        this.setState({
          action,
          editNeed: false
        });
      }
    } else {
      const cns = await api.listNeeds();
      this.setState({
        editNeed: true,
        needs: cns.data,
      });
    }
  }

  comment = () => {
    const { action, comment, logs } = this.state;

    if (action && comment !== '') {
      api.storeActionComment(action.id, {
        message: this.state.comment,
      }).then((res) => {
        let newLogs: any = [];

        if (logs && logs.length) {
          newLogs = [...logs, res];
        }

        this.setState({
          tacFocus: false,
          comment: '',
          logs: newLogs,
        });
      });
    }
  }

  patchAction = (data: Partial<ApiActionObject>) => {// @TODO update lijst/cards
    const { action } = this.state;

    if (action) {
      this.setState({
        action: {
          ...action,
          ...data,
        },
      })

      api.updateAction(action.id, data).then(() => {
        this.fetchLogs();

        if (data.need_id || data.status || data.subject || data.deadline || data.archived_at || data.assignee_id) {
          window.dispatchEvent(new CustomEvent('masterboard_action_added'));
          socket.emit('action changed', action.id);
        }
      });
    }
  }

  addLog = (msg: String, large?: Boolean) => {
    const { logs } = this.state;
    // if (logs && logs[logs.length - 1].msg !== msg) {
    //   logs.push({
    //     user: 'Jelle van der Coelen',
    //     time: moment(),
    //     msg,
    //     large,
    //   });
    // }
  }

  onClose = () => {
    this.setState({
      duplicate: false,
      confirmDelete: false,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  deleteAction = () => {
    const { action } = this.state;

    if (action && action.id) {
      api.destroyAction(action.id).then(() => {
        this.onClose();
        window.dispatchEvent(new CustomEvent('masterboard_action_added'));
        socket.emit('action deleted', action.id);
        toast({
          type: 'success',
          description: 'Action deleted succesfully',
          time: 2500,
        });
      }).then(() => socket.emit('action deleted', action.id));
    }
  }

  editSubject = () => {
    this.setState({
      editTitle: true
    }, () => {
      const edit = document.getElementById('edit-subject')
      if (edit) {
        edit.focus();
      }
    })
  }

  onPickFile = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    const currentUser = authInstance.currentUser.get();
    const { access_token } = currentUser.getAuthResponse(true);

    const view = new google.picker.DocsView()
      .setParent('root')
      .setIncludeFolders(true)
      // .setEnableTeamDrives(true)
      // .setSelectFolderEnabled(true);
      // .setIncludeFolders(true)
      // .setSelectFolderEnabled(true)
      // .setOwnedByMe(true)
      // // .setEnableTeamDrives(true)
      .setMode(google.picker.DocsViewMode.GRID)

    const picker = new google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(access_token)
      .setDeveloperKey('AIzaSyCblWfkmGoxO_2mFZujsVfUSOItGazmt24')
      .setCallback((result: any) => {
        if (result.action === 'picked') {
          const doc = result.docs[0];
          const driveDocument: ApiGoogleDriveDocumentObject = {
            name: doc.name,
            iconUrl: doc.iconUrl,
            url: doc.url,
          };
          if (this.props.action) {
            api.storeActionDocuments(this.props.action, driveDocument).then(o => {
              this.setState((state: PanelState) => {
                const docs = state.action ? [...state.action.documents || []] : [];
                return {
                  action: { ...state.action, documents: [...docs, o] } as ApiActionObject,
                };
              })
            });
          }
        }
      })
      .build()

    picker.setVisible(true);
  }

  toggleDuplicate = (id: number) => {
    const { duplicates } = this.state;
    const index = duplicates.indexOf(id);

    if (index === -1) {
      duplicates.push(id);
    } else {
      duplicates.splice(index, 1);
    }

    this.setState({ duplicates });
  }

  duplicateAction = () => {
    const { action, duplicates } = this.state;

    if (action && duplicates.length) {
      api.duplicateAction(action.id, duplicates).then((res) => {
        this.setState({
          duplicates: [],
          duplicate: false,
        });
        toast({
          type: 'success',
          description: 'Action duplicated succesfully',
          time: 2500,
        });
        window.dispatchEvent(new CustomEvent('masterboard_action_added'));
        socket.emit('action added', res.id);
      });
    }
  }

  updateAccounts = async (accountIds: number[]) => {
    const { action } = this.state;
    if (action) {
      await this.patchAction({ accounts: accountIds });
      window.dispatchEvent(new CustomEvent('masterboard_action_added'));
      socket.emit('action changed', action.id);
      const accounts: ApiAccountObject[] = [];
      accountIds.map(o => accounts.push(find(this.props.accounts, { id: o }) as ApiAccountObject));
      action.accounts = accounts;
      this.setState({ toggleAccounts: false, action });
    }
  }

  deleteDocument = (documentId?: number) => {
    const { action } = this.state;
    if (action && documentId) {
      api.deleteActionDocument(action.id, documentId).then((res: any) => {
        action.documents = res;
        this.setState({ action });
        this.fetchLogs();
      });
    }
  }

  renderFiles() {
    if (this.state.action && this.state.action.documents) {
      const docs = this.state.action.documents.map(doc => {
        return (
          <li className={styles.filesListItem} key={`doc-${doc.id}`}>
            <DocumentLink document={doc} showDelete onDelete={() => this.deleteDocument(doc.id)} />
          </li>
        )
      });
      return (
        <ul className={styles.filesList}>
          {docs}
        </ul>
      );
    }
    return <></>;
  }

  render() {
    const { accounts } = this.props;
    const { action, logs, comment, confirmDelete, editTitle, duplicates, accountFilter } = this.state;

    if (!action) {
      return null;
    }

    
    const userVals = Object.values(this.props.users);
    const me = userVals.find(o => o.id === this.props.currentUserId)
    const others = userVals.filter(o => o.id !== this.props.currentUserId);
    const users = me ? [me, ...others] : others;

    return (
      <Sidepanel width={600} open={this.props.open} onClose={this.onClose} flex>
        <Sidepanel.Content>
          <div className={styles.topContainer}>
            <div className={styles.status}>
              <Status
                statusName={action.status_name}
                size="large"
                statusId={action.status}
              />
            </div>
            <div style={{ paddingTop: 8 }}>
              <Dropdown style={{ minWidth: 92 }}>
                <Dropdown.Menu>
                  {['Incoming', 'Backlog', 'To-do', 'Doing', 'Done'].map((i, n) => {
                    if (n === action.status) {
                      return null;
                    }

                    return <Dropdown.Item key={n} onClick={() => this.patchAction({ status: n, status_name: i })}>
                      <Status
                        statusName={i}
                        size="tiny"
                        statusId={n}
                      />  
                    </Dropdown.Item>
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={styles.globalActions}>
              <Button basic icon="copy outline" onClick={() => this.setState({ duplicate: true })} />
              <Button
                onClick={this.onPickFile}
                basic
                icon="linkify"
              />
              <Button
                onClick={() => this.patchAction({ archived_at: action.archived_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss') })}
                basic
                icon="archive"
              />
              <Button
                onClick={() => this.setState({ confirmDelete: true })}
                basic
                icon="trash"
              />
              <Confirm
                header="Delete action"
                content="Are you sure you want to delete this action? This cannot be undone."
                open={confirmDelete}
                onCancel={() => this.setState({ confirmDelete: false })}
                onConfirm={this.deleteAction}
                size="small"
              />
            </div>
          </div>
          <div className={editTitle ? styles.subjectEdit : styles.subject}>
            {editTitle ? (
              <div style={{ marginBottom: 5 }}>
                <Input
                  id="edit-subject"
                  name="subject"
                  value={action.subject}
                  fluid
                  onBlur={() => {
                    this.setState({ editTitle: false });
                    this.patchAction({ subject: action.subject });
                  }}
                  onChange={(e: any, { value }) => {
                    this.setState({
                      action: {
                        ...action,
                        subject: value,
                      },
                    });
                  }}
                />
              </div>
            ) : (
              <span onClick={this.editSubject} style={{ display: 'block', cursor: 'pointer' }}>
                {action.subject}
              </span>
            )}
            {action.need && !this.state.editNeed && (
              <div style={{ display: 'flex' }}>
                <Link to={`/needs?need=${action.need.id}`} onClick={this.props.onClose}>
                  {action.need.name}
                </Link>
                <Icon name="pencil" style={{ marginLeft: 10, fontSize: 12, cursor: 'pointer' }} onClick={this.toggleEditNeed} />
              </div>
            )}
            {action.need && this.state.editNeed && (
              <Dropdown
                name="need_id"
                options={this.state.needs.map((o) => ({
                  key: `need-${o.id}`,
                  value: o.id,
                  text: o.name,
                }))}
                search
                selection
                value={action.need_id}
                selectOnBlur={false}
                onChange={(e: any, data: any) => this.toggleEditNeed(data.value)}
              />
            )}
          </div>
          <div className={styles.extraInfo}>
            <UserPicker
              userId={action.assignee_id}
              onChange={async (user) => {
                this.setState({
                  action: {
                    ...action,
                    assignee: user,
                    assignee_id: user.id,
                  }
                });
                this.patchAction({ assignee_id: user.id });
              }}
            />
            <DatePicker
              date={action.deadline ? moment(action.deadline, dateFormat) : undefined}
              onChange={async (date) => {
                const deadline = date as unknown as Date;
                this.setState({
                  action: {
                    ...action,
                    deadline,
                  }
                });
                this.patchAction({ deadline });
              }}
            />
            {Object.values(action.accounts).length <= 0 && (
              <span onClick={() => this.setState({ toggleAccounts: true })} style={{ cursor: 'pointer' }}>
                + Add account
              </span>
            )}
          </div>
          {this.state.duplicate ? (
            <div style={{ paddingTop: 15 }}>
              <Header as="h4">Duplicate this action for these accounts:</Header>
              <Input
                fluid
                iconPosition="left"
                icon="search"
                onChange={(e: any, data: any) => this.setState({ accountFilter: data.value })}
              />
              <div className={styles.duplicateScroller}>
                {accounts.length > 0 && accounts.filter(a => accountFilter === '' || !a.name || a.name.toLowerCase().indexOf(accountFilter.toLowerCase()) !== -1).map((account) => {
                  if (account.id) {
                    return (<Segment key={`dup-${account.id}`} style={{ cursor: 'pointer' }} onClick={() => this.toggleDuplicate(account.id as number)}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          <Checkbox
                            style={{ marginRight: 10, position: 'relative', top: 3 }}
                            checked={duplicates.indexOf(account.id) !== -1}
                          />
                          {account.name}
                        </div>
                        <AccountLink account={account}/ >
                      </div>
                    </Segment>)
                  }
                  return null;
                })}
              </div>
            </div>
          ) : (
            <>
              <Form className={styles.description}>
                <Editor
                  ref={r => this.descriptionEditor = r}
                  defaultValue={this.state.action ? this.state.action.description : ''}
                  onBlur={() => {
                    if (this.descriptionEditor) {
                      const content = JSON.stringify(this.descriptionEditor.getRaw());
                      if (action.description !== content) {
                        this.setState({
                          action: {
                            ...action,
                            description: content,
                          }
                        });
                        this.patchAction({ description: content });
                      }
                    }
                  }}
                />
              </Form>
              {(Object.values(action.accounts).length > 0 || this.state.toggleAccounts) && (
                <div className={styles.accounts}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{flex: 1, display: 'flex'}}>
                      {action.accounts && Object.values(action.accounts).map((account) => (
                        <AccountLink account={account} key={`linka-${account.id}`} style={{ marginRight: 15 }} />
                      ))}
                    </div>
                    {!this.state.toggleAccounts ? (
                      <Icon name="pencil" style={{ marginLeft: 10, fontSize: 12, cursor: 'pointer' }} onClick={() => this.setState({ toggleAccounts: true })} />
                    ) : (
                      <Icon name="close" style={{ marginLeft: 10, fontSize: 12, cursor: 'pointer' }} onClick={() => this.setState({ toggleAccounts: false })} />
                    )}
                  </div>
                  {this.state.toggleAccounts && (<Dropdown
                    name="accounts"
                    options={accounts.map((o) => ({
                      key: `need-${o.id}`,
                      value: o.id,
                      text: o.name,
                    }))}
                    fluid
                    search
                    selection
                    multiple
                    value={action.accounts ? Object.values(action.accounts).map(o => o.id) : undefined}
                    selectOnBlur={false}
                    onChange={(e: any, data: any) => this.updateAccounts(data.value)}
                    style={{ marginTop: 5 }}
                  />)}
                </div>
              )}
              <div className={styles.files}>
                {this.renderFiles()}
              </div>
              <div className={styles.logs}>
                {logs && logs.map((log) => (
                  <div className={styles.log} key={`log-${log.id}`}>
                    {log.action === 'comment' ? (
                      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                        {log.user && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Image src={log.user.avatar} avatar style={{ marginRight: 5 }} />
                            <p style={{ margin: 0 }}>
                              {log.user.full_name}
                              <span>{log.diff_for_humans}</span>
                            </p>
                          </div>
                        )}
                        {log.extra && log.extra.content}
                      </div>
                    ) : (
                      <div>
                        {log.user && <b>{log.user.full_name}</b>} {log.message}
                        <span>{log.diff_for_humans}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {!this.state.duplicate && 
            <div className={styles.comments}>
              <Image src={this.props.authAvatar} avatar style={{ marginRight: 10 }} />
              <Form>
                <TextArea
                  rows={comment.length > 0 || this.state.tacFocus ? 5 : 1}
                  placeholder="Write a comment"
                  value={comment}
                  onFocus={() => this.setState({ tacFocus: true })}
                  onBlur={() => this.setState({ tacFocus: false })}
                  onChange={(e: FormEvent<HTMLTextAreaElement>) => this.setState({ comment: e.currentTarget.value })}
                />
                <Button
                  style={{ opacity: comment.length > 0 && this.state.tacFocus ? 1 : 0 }}
                  className={styles.commentButton}
                  primary
                  onClick={this.comment}
                >Comment</Button>
              </Form>
            </div>
          }
        </Sidepanel.Content>
        <Sidepanel.Footer>
          <div style={{ flex: 1, position: 'relative', zIndex: 2000 }}>
            
            {this.state.duplicate ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button basic onClick={() => this.setState({ duplicate: false })}>Cancel</Button>
                <Button primary onClick={this.duplicateAction}>Duplicate</Button>
              </div>
            ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* <div className={styles.squad}>
                <p>Squad Early Pipeline</p>
                <Image src="https://lh3.googleusercontent.com/-IzhHaMlsOfM/AAAAAAAAAAI/AAAAAAAAAdY/s254OQGibG8/s96-c/photo.jpg" avatar />
                <Image src="https://lh3.googleusercontent.com/--FiIa6Suwpc/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rfS_SpMrCprdwfqOyKUPQ1uH-FXAQ/s96-c/photo.jpg" avatar />
              </div> */}
              <span />
              <Button basic onClick={this.props.onClose}>Close</Button>
            </div>
            )}
          </div>
        </Sidepanel.Footer>
      </Sidepanel>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: OwnProps) => ({
    accounts: state.accounts.minimal,
    users: state.users.users,
    authAvatar: state.auth.avatar,
    currentUserId: state.auth.id,
  }),
)(ActionPanel);
