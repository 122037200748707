import React from 'react';
import { ApiGoogleDriveDocumentObject } from '../../api/actions';
import styles from './DocumentLink.module.scss';
import { Icon } from 'semantic-ui-react';

interface Props {
  document: ApiGoogleDriveDocumentObject,
  showDelete?: boolean;
  onDelete: () => void
};

export default function DocumentLink({ document, showDelete, onDelete, ...props }: Props) {


  const deleteButton = showDelete ? (
    <button className={styles.delete} onClick={onDelete}><Icon name="minus circle" /></button>
  ) : null;
  return (
    <div className={styles.wrapper}>
      <a
        href={document.url}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <img className={styles.icon} src={document.iconUrl} alt="File" />
        <span className={styles.name}>{document.name}</span>
      </a>
      {deleteButton}
    </div>
  );
}
