import React, { Component, KeyboardEvent, ChangeEvent, FocusEvent, createRef } from 'react';
import { Input, Popup, Modal, Button, Icon, Dropdown, Form, DropdownProps, Loader, Dimmer, Image, Confirm, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import { toast } from 'react-semantic-toasts';
import { ApiAccountObject } from '../../api/admin/accounts';
import { ApiUserObject } from '../../api/admin/users';
import { ApplicationState } from '../../reducers';
import actions, { ApiActionObject, ApiGoogleDriveDocumentObject } from '../../api/actions';
import styles from './ActionModal.module.scss';
import api, { dateFormat } from '../../api/api';
import UserPicker from '../TogglePicker/UserPicker';
import DatePicker from '../TogglePicker/DatePicker';
import Status from '../Status/Status';
import socket from '../../lib/socket';
import LinkButton from '../LinkButton/LinkButton';
import Editor from '../Editor/Editor';
import { ApiNeedObject } from '../../api/needs';
import { getDropdownOptions } from '../../lib/getDropdownOptions';
import { ApiSquadObject } from '../../api/admin/squads';
import chapters from '../../api/chapters';
import { ApiChapterObject } from '../../api/admin/chapters';

interface OwnProps {
  open?: boolean;
  onClose?: () => void;
  action?: number;
}

interface StateProps {
  accounts: Partial<ApiAccountObject>[];
  squads: { [key: string]: ApiSquadObject };
}

type Props = OwnProps & StateProps;

interface ActionModalState {
  action: ApiActionObject | null;
  logs?: any[];
  needs: ApiNeedObject[],
  editNeed: boolean;
  selectedAccounts: number[];
  selectedSquad: number, 
  selectedChapter: number, 
  squads: ApiSquadObject[],
  chapters: ApiChapterObject[],
  isFetching: boolean;
  confirmDelete: boolean;
  openModal: boolean | string,
  newLabel: string | boolean,
}

class ActionModal extends Component<Props, ActionModalState> {
  descriptionEditor: Editor | null;
  needDropdown?: DropdownProps | null;
  accountsDropdown?: DropdownProps | null;
  chapterDropdown?: DropdownProps | null;
  squadDropdown?: DropdownProps | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      action: null,
      editNeed: false,
      logs: [],
      needs: [],
      selectedAccounts: [],
      selectedSquad: -1,
      selectedChapter: -1,
      squads: [],
      chapters: [],
      isFetching: true,
      confirmDelete: false,
      openModal: false,
      newLabel: false,
    };
    this.descriptionEditor = null;
  }

  componentWillReceiveProps = async (props: any) => {
    if (props.action !== this.props.action && props.action > 0) {
      this.setState({
        isFetching: true,
      });

      // const squads = await api.listSquads();
      // const chapters = await api.listChapters();
      // const needs = await api.listNeeds();
      
      // this.setState({
      //   squads,
      //   chapters,
      // needs: needs.data,
      // });


      api.showAction(props.action, [
        'squad',
        'chapter',
        'need',
        'accounts',
        'documents'
      ]).then((res) => {

        const accountIds = (res.accounts as ApiAccountObject[])
          .map(o => o.id);

        this.setState({
          action: res,
          selectedSquad: res.squad_id || -1,
          selectedChapter: res.chapter_id || -1,
          selectedAccounts: accountIds,
        });



        this.fetchLogs();
        
      });
    }
  }

  fetchLogs = () => {
    const { action } = this.state;
    
    if (action) {// @TODO: paginering
      api.listActionLogs(action.id).then((res) => {
        if (!res) return;
        this.setState({
          logs: res.data,
          isFetching: false,
        });
      });
    }
  }

  patchAction = (data: Partial<ApiActionObject>) => {
    this.setState(state => {
      const { action } = state;
      if (!action) return {} as ActionModalState;

      const newState = {
        action: {
          ...action,
          ...data,
        },
      };

      api.updateAction(action.id, data).then(() => {
        this.fetchLogs();
        if (data.need_id || data.status || data.subject || data.deadline || data.archived_at || data.assignee_id) {
          window.dispatchEvent(new CustomEvent('masterboard_action_added'));
          socket.emit('action changed', action.id);
        }
      });

      return newState;
    });
  }

  onClose = () => {
    this.setState({
      confirmDelete: false,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onSubjectBlur = () => {
    this.patchAction({
      subject: this.state.action ? this.state.action.subject : '',
    })
  };

  onSubjectChange = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState(state => ({
      action: Object.assign(state.action || {}, { subject: value }),
    } as ActionModalState));
  }

  onNeedEditClick = async (_id?: number) => {
    const id = _id;

    if (this.state.needs.length === 0) {
      const needs = await api.listNeeds();
      this.setState({ needs: needs.data });
    }

    this.setState(state => {
      if (typeof id === 'number') {
        this.patchAction({ need_id: id });
        
        const newAction = { ...state.action } as ApiActionObject;
        newAction.need = find(this.state.needs, { id });
        return {
          editNeed: false,
          action: newAction,
        } as ActionModalState;
      }

      return {
        editNeed: !state.editNeed,
      };
    });
  }

  onAssigneeChange = async (user: ApiUserObject) => {
    this.setState(state => ({
      action: {
        ...state.action,
        assignee: user,
        assignee_id: user.id,
      },
    } as ActionModalState));
    this.patchAction({ assignee_id: user.id });
  }

  onDeadlineChange = (date?: string) => {
    const deadline = date as unknown as Date;
    this.patchAction({
      deadline,
    });
  }

  onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    this.setState(state => ({
      action: {
        ...state.action,
        description: value,
      }
    } as ActionModalState));
    this.patchDescription({
      description: value,
    });
  }
  patchDescription = debounce(this.patchAction, 300);

  onAccountChange = (_: any, props: DropdownProps) => {
    const ids = props.value as number[];
    this.patchAction({ accounts: ids });
    this.setState({
      selectedAccounts: ids,
      openModal: false,
    });
  }

  onSquadChange = (_:any, props: DropdownProps) => {
    const id = props.value as number;
    this.patchAction({ squad_id: id });
    this.setState({
      selectedSquad: props.value as number,
      openModal: false,
    });
  }

  onChapterChange = (_:any, props: DropdownProps) => {
    const id = props.value as number;
    this.patchAction({ chapter_id: id });
    this.setState({
      selectedChapter: props.value as number,
      openModal: false,
    });
  }

  onDeleteAction = () => {
    const { action } = this.state;

    if (action && action.id) {
      api.destroyAction(action.id).then(() => {
        this.onClose();
        window.dispatchEvent(new CustomEvent('masterboard_action_added'));
        socket.emit('action deleted', action.id);
        toast({
          type: 'success',
          description: 'Action deleted succesfully',
          time: 2500,
        });
      }).then(() => socket.emit('action deleted', action.id));
    }
  }

  addLabel = () => {
    const { newLabel, action } = this.state;
    if (action && newLabel !== false) {
      const label = newLabel as string;
      action.labels.push({ name: label, slug: label });
      this.patchAction({ labels: action.labels });
    }

    this.setState({
      newLabel: false,
      action,
    });
  }

  removeLabel = (index: number) => {
    const { action } = this.state;
    if (action) {
      action.labels.splice(index, 1);
      this.patchAction({ labels: action.labels });
    }

    this.setState({
      action,
    });
  }

  onPickFile = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    const currentUser = authInstance.currentUser.get();
    const { access_token } = currentUser.getAuthResponse(true);

    const view = new google.picker.DocsView()
      .setParent('root')
      .setIncludeFolders(true)
      // .setEnableTeamDrives(true)
      // .setSelectFolderEnabled(true);
      // .setIncludeFolders(true)
      // .setSelectFolderEnabled(true)
      // .setOwnedByMe(true)
      // // .setEnableTeamDrives(true)
      .setMode(google.picker.DocsViewMode.GRID)

    const picker = new google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(access_token)
      .setDeveloperKey('AIzaSyCblWfkmGoxO_2mFZujsVfUSOItGazmt24')
      .setCallback((result: any) => {
        if (result.action === 'picked') {
          const doc = result.docs[0];
          const driveDocument: ApiGoogleDriveDocumentObject = {
            name: doc.name,
            iconUrl: doc.iconUrl,
            url: doc.url,
          };
          if (this.props.action) {
            api.storeActionDocuments(this.props.action, driveDocument).then(o => {
              this.setState((state: ActionModalState) => {
                const docs = state.action ? [...state.action.documents || []] : [];
                return {
                  action: { ...state.action, documents: [...docs, o] } as ApiActionObject,
                };
              })
            });
          }
        }
      })
      .build()

    picker.setVisible(true);
  }

  renderTitleAndActions() {
    const { action } = this.state;
    if (!action) return null;

    return (
      <div className={styles.flex}>
        <input 
          className={styles.title}
          onBlur={this.onSubjectBlur}
          onInput={this.onSubjectChange}
          value={action.subject}
        />
        <div>
          <Popup size="tiny" inverted content="Duplicate" trigger={<Button basic icon="copy outline"/>} />
          <Popup size="tiny" inverted content="Attachments" trigger={<Button
            onClick={this.onPickFile}
            basic
            icon="linkify"
          />} />
          <Popup size="tiny" inverted content="Archive" trigger={<Button
            basic
            onClick={() => this.patchAction({ archived_at: action.archived_at ? '' : moment().format('YYYY-MM-DD HH:mm:ss') })}
            icon="archive"
          />} />
          <Popup size="tiny" inverted content="Delete" trigger={<Button
            basic
            onClick={() => this.setState({ confirmDelete: true })}
            icon="trash"
          />} />
          <Confirm
            header="Delete action"
            content="Are you sure you want to delete this action? This cannot be undone."
            open={this.state.confirmDelete}
            onCancel={() => this.setState({ confirmDelete: false })}
            onConfirm={this.onDeleteAction}
            size="small"
          />
        </div>
      </div>
    );
  }

  renderNeed() {
    const { action } = this.state;
    if (!action) return null;
    if (action.need/* && !this.state.editNeed*/) {
      return (
        <>
        <div className={styles.need}>
          <Link to={`/needs?need=${action.need.id}`} onClick={this.props.onClose}>
            {action.need.name}
          </Link>
          <Icon name="pencil" className={styles.editNeedButton} onClick={this.onNeedEditClick} />
        </div>
        <Modal open={this.state.editNeed} onClose={() => this.setState({ editNeed: false })} size="small">
          <Modal.Header>Select need</Modal.Header>
          <Modal.Content>
            {this.state.needs.length > 0 && (<Dropdown
              name="need_id"
              options={this.state.needs.map((o) => ({
                key: `need-${o.id}`,
                value: o.id,
                text: o.name,
              }))}
              search
              selection
              value={action.need_id}
              selectOnBlur={false}
              onChange={(e: any, data: any) => this.onNeedEditClick(data.value)}
              // ref={r => this.needDropdown = r}
              style={{ flex: 1 }}
              fluid
            />)}
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => this.setState({ editNeed: false })}>Ok</Button>
          </Modal.Actions>
        </Modal>
        </>
      )
    }
  }

  renderStatusLine() {
    const { action } = this.state;
    if (!action) return null;

    return (
      <>
      <div className={styles.statusLine}>
        For&nbsp;
        <UserPicker
          userId={action.assignee_id}
          onChange={this.onAssigneeChange}
          slim
        />
        &nbsp;Due&nbsp;
        <DatePicker
          date={action.deadline ? moment(action.deadline, dateFormat) : undefined}
          onChange={this.onDeadlineChange}
          slim
        />
        &nbsp;Status&nbsp;
        <Status statusId={action.status} size="large" />
        <Dropdown>
        <Dropdown.Menu>
            {['Incoming', 'Backlog', 'To-do', 'Doing', 'Done'].map((i, n) => {
              if (n === action.status) {
                return null;
              }

              return <Dropdown.Item key={n} onClick={() => this.patchAction({ status: n, status_name: i })}>
                <Status
                  statusName={i}
                  size="tiny"
                  statusId={n}
                />  
              </Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {(this.state.newLabel !== false || action.labels.length > 0) && (<div className={styles.labels}>
        {action.labels.map((l, index) => (<Label tag>
          {l.name}
          <Icon name="delete" onClick={() => this.removeLabel(index)} />
        </Label>))}
        {this.state.newLabel !== false ? (<>
          <Input
            style={{ marginLeft: 10 }}
            value={this.state.newLabel}
            onChange={(e: any, data: any) => this.setState({ newLabel: data.value })}
            onKeyup={(e: any) => {
              if (e.keyCode === 13) {
                this.addLabel();
              }
            }}
          />
          <Button icon="save" basic size="tiny" onClick={this.addLabel} />
          <Button icon="times" basic size="tiny" onClick={() => this.setState({ newLabel: false })} />
        </>) : (
          <Button icon="plus" basic size="tiny" onClick={() => this.setState({ newLabel: '' })} />
        )}
      </div>)}
      </>
    )
  }

  renderDescription() {
    const { action } = this.state;
    if (!action) return null;
    return (
      <div className={styles.flex}>
        <div className={styles.descriptionWrapper}>
          <label htmlFor="description">Description</label>
          <Editor
            ref={r => this.descriptionEditor = r}
            defaultValue={this.state.action ? this.state.action.description : ''}
            onBlur={() => {
              if (this.descriptionEditor) {
                const content = JSON.stringify(this.descriptionEditor.getRaw());
                if (action.description !== content) {
                  this.setState({
                    action: {
                      ...action,
                      description: content,
                    }
                  });
                  this.patchAction({ description: content });
                }
              }
            }}
          />
        </div>
        <div className={styles.linkButtons}>
          <label>Details</label>
          <LinkButton onClick={this.onNeedEditClick}>Need</LinkButton>
          <LinkButton onClick={() => {
            // this.accountsDropdown!.searchRef.focus();
            this.setState({ openModal: 'accounts' });
          }}>Account</LinkButton>
          <LinkButton onClick={async () => {
            if (this.state.chapters.length <= 0) {
              const chapters = await api.listChapters();
              this.setState({ chapters });
            }
            this.setState({ openModal: 'chapters' });
            // this.chapterDropdown!.searchRef.focus();
          }}>Chapter</LinkButton>
          <LinkButton onClick={async () => {
            if (this.state.squads.length <= 0) {
              const squads = await api.listSquads();
              this.setState({ squads });
            }
            this.setState({ openModal: 'squads' });
          }}>Squad</LinkButton>
          <LinkButton linkIcon="tags" onClick={() => this.setState({ newLabel: '' })}>Labels</LinkButton>
        </div>
      </div>
    );
  }


  renderDetails() {
    const { action, logs } = this.state;
    if (!action) return null;

    return (
      <div className={styles.flex}>
        <div className={styles.details}>
          {this.state.selectedAccounts.length > 0 && (<div className={styles.labelDropdown}>
            <label htmlFor="acmo-account">Accounts</label>
            <Dropdown
              className={styles.invDropdown}
              id="acmo-account"
              name="acmo-account"
              fluid
              search
              selection
              multiple
              value={this.state.selectedAccounts}
              onChange={this.onAccountChange}
              options={getDropdownOptions(this.props.accounts)}
              clearable
              ref={r => this.accountsDropdown = r}
            />
          </div>)}
          {this.state.selectedChapter > 0 && (<div className={styles.labelDropdown}>
            <label htmlFor="acmo-account">Chapter</label>
            <Dropdown
              className={styles.invDropdown}
              id="acmo-chapter"
              name="acmo-chapter"
              fluid
              search
              selection
              value={this.state.selectedChapter}
              onChange={this.onChapterChange}
              options={getDropdownOptions(this.state.chapters)}
              clearable
              // multiple
              ref={r => this.chapterDropdown = r}
            />
          </div>)}
          {this.state.selectedSquad > 0 && (<div className={styles.labelDropdown}>
            <label htmlFor="acmo-account">Squad</label>
            <Dropdown
              className={styles.invDropdown}
              id="acmo-squads"
              name="acmo-squads"
              fluid
              search
              selection
              value={this.state.selectedSquad}
              options={getDropdownOptions(this.state.squads)}
              onChange={this.onSquadChange}
              selectOnBlur={false}
              clearable
              // multiple
              ref={r => this.squadDropdown = r}
            />
          </div>)}
        </div>
        <div className={styles.logsWrapper}>
          <div className={styles.logs}>
            {logs && logs.map((log) => (
              <div className={styles.log} key={`log-${log.id}`}>
                {log.action === 'comment' ? (
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    {log.user && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Image src={log.user.avatar} avatar style={{ marginRight: 5 }} />
                        <p style={{ margin: 0 }}>
                          {log.user.full_name}
                          <span>{log.diff_for_humans}</span>
                        </p>
                      </div>
                    )}
                    {log.extra && log.extra.content}
                  </div>
                ) : (
                  <div>
                    {log.user && <b>{log.user.full_name}</b>} {log.message}
                    <span>{log.diff_for_humans}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { action } = this.state;
    if (!action) return null;

    return (
      <>
      <Modal open={this.props.open} onClose={this.onClose} size="large">
        <Modal.Header>Create new action</Modal.Header>
        <Modal.Content>
          {this.renderTitleAndActions()}
          {this.renderNeed()}
          {this.renderStatusLine()}
          {this.renderDescription()}
          {this.renderDetails()}
          <Dimmer active={this.state.isFetching}>
            <Loader />
          </Dimmer>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.props.onClose}>Close</Button>
        </Modal.Actions>
      </Modal>

      <Modal open={this.state.openModal === 'chapters'} onClose={() => this.setState({ openModal: false })} size="small">
        <Modal.Header>Select chapter</Modal.Header>
        <Modal.Content>
          {this.state.chapters.length > 0 && (
            <Dropdown
              // className={styles.invDropdown}
              // id="acmo-chapter"
              name="acmo-chapter"
              fluid
              search
              selection
              value={this.state.selectedChapter}
              onChange={this.onChapterChange}
              options={getDropdownOptions(this.state.chapters)}
              clearable
            />)}
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => this.setState({ openModal: false })}>Ok</Button>
        </Modal.Actions>
      </Modal>

      <Modal open={this.state.openModal === 'squads'} onClose={() => this.setState({ openModal: false })} size="small">
        <Modal.Header>Select squad</Modal.Header>
        <Modal.Content>
          {this.state.squads.length > 0 && (
            <Dropdown
              // className={styles.invDropdown}
              // id="acmo-chapter"
              name="acmo-chapter"
              fluid
              search
              selection
              value={this.state.selectedSquad}
              onChange={this.onSquadChange}
              options={getDropdownOptions(this.state.squads)}
              clearable
            />)}
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => this.setState({ openModal: false })}>Ok</Button>
        </Modal.Actions>
      </Modal>

      <Modal open={this.state.openModal === 'accounts'} onClose={() => this.setState({ openModal: false })} size="small">
        <Modal.Header>Select account(s)</Modal.Header>
        <Modal.Content>
          <Dropdown
            name="acmo-account"
            fluid
            search
            selection
            multiple
            value={this.state.selectedAccounts}
            onChange={this.onAccountChange}
            options={getDropdownOptions(this.props.accounts)}
            clearable
            ref={r => this.accountsDropdown = r}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => this.setState({ openModal: false })}>Ok</Button>
        </Modal.Actions>
      </Modal>
      </>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: OwnProps) => ({
    accounts: state.accounts.minimal,
    squads: state.squads.squads,
  }),
)(ActionModal);
