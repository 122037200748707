import accounts from './accounts';
import squads from './squads';
import users from './users';
import chapters from './chapters';


export default {
  accounts,
  squads,
  users,
  chapters,
};