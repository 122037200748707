import { toast } from 'react-semantic-toasts';
import config from '../config.json';
import store from '../store';
import { logout, login, updateProfile, updateRoles, updateFetchState } from '../reducers/auth';
import { mapObjectToId } from './objectToId';
import api from '../api/api';


export function init() {
  window.removeEventListener('mwitGapiLoaded', init);
  if ((window as any).gapi) {
    // Window has a gapi object, start using it.
    gapi.load('auth2', onAuth2Load);
    gapi.load('picker', () => {});
  } else {
    // Window does not have a gapi object, listen for it to load. This event is thrown in index.html.
    // `<script>function onGapiLoaded() { window.dispatchEvent(new Event('mwitGapiLoaded')); }</script>`
    window.addEventListener('mwitGapiLoaded', init);
  }
}

async function onSignedInChange() {
  const authInstance = gapi.auth2.getAuthInstance();
  const isSignedIn = authInstance.isSignedIn.get();
  if (isSignedIn) {
    const user = authInstance.currentUser.get();
    const authResponse = user.getAuthResponse(true);
    const profile = user.getBasicProfile();

    store.dispatch(login(authResponse));

    const masterboardProfile = await api.showAuthProfile();
    store.dispatch(updateRoles(mapObjectToId(masterboardProfile.roles || [])));

    store.dispatch(updateProfile({
      id: masterboardProfile.id,
      name: profile.getName(),
      email: profile.getEmail(),
      avatar: profile.getImageUrl(),
    }));

    store.dispatch(updateFetchState(false));

    const reset = localStorage.getItem('__may_reset');
    if (reset && reset !== config.reset) {
      localStorage.setItem('__may_reset', config.reset);
      localStorage.removeItem('filterneeds');
      localStorage.removeItem('filteractions');
      toast({
        type: 'success',
        description: 'Roche masterboard updated. Please sign in again.',
        time: 5000,
      });
      await signOut()
      window.location.reload();
    } else {
      localStorage.setItem('__may_reset', config.reset);
    }
  } else {
    store.dispatch(logout());
  }
}

async function onAuth2Load() {
  await gapi.auth2.init({
    client_id: config.googleClientID,
  }).then((e: any) => console.log('Google Auth', e))
    .catch((e: any) => console.error(e));

  // Check if the user is already signed-in.
  onSignedInChange();
  // Also listen for changes, it might still be unknown if the user is logged in.
  gapi.auth2.getAuthInstance().isSignedIn.listen(onSignedInChange);
}

export function signIn() {
  store.dispatch(updateFetchState(true));
  return gapi.auth2.getAuthInstance().grantOfflineAccess({
    scope: 'https://www.googleapis.com/auth/drive.file',
  });
}

export function signOut() {
  return gapi.auth2.getAuthInstance().signOut();
}

export default {
  init,
  signIn,
  signOut,
}
