import React from 'react';
import { Label } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

export type Status = 'incoming' | 'backlog' | 'open' | 'progress' | 'done';

interface StatusProps {
  status?: Status;
  statusName?: string;
  statusId?: number;
  size?: "big" | "small" | "mini" | "tiny" | "large" | "huge" | "massive" | "medium" | undefined;
}

const idStatusMap: { [key: number]: string } = {
  0: 'incoming',
  1: 'backlog',
  2: 'open',
  3: 'progress',
  4: 'done',
}
const colors: { [key: string]: SemanticCOLORS } = {
  incoming: 'grey',
  backlog: 'grey',
  open: 'red',
  progress: 'yellow',
  done: 'green',
}

const labels = {
  incoming: 'Incoming',
  backlog: 'Backlog',
  open: 'To-do',
  progress: 'Doing',
  done: 'Done',
};

export default function Status(props: StatusProps) {
  const { status, statusName, statusId, size = 'small' } = props;
  let color: SemanticCOLORS = 'grey';
  let label = '';
  if (status) {
    color = colors[status] as SemanticCOLORS;
    label = labels[status];
  } 
  if (statusName) {
    label = statusName;
  }
  if (statusId) {
    const n = idStatusMap[statusId] as string;
    if (n) {
      color = colors[n];
    }
  }

  if (statusId != null && !statusName) {
    const n = idStatusMap[statusId] as Status;
    label = labels[n];
  }



  return (
    <Label
      color={color}
      horizontal
      size={size}
    >
      {label}
    </Label>
  );
}
