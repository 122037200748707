import { createReducer } from "redux-starter-kit";
import { createAction } from 'redux-actions';
import { Dispatch } from "redux";
import { pickBy } from "lodash";
import { ApiChapterObject } from "../api/admin/chapters";
import { ApiResponse, ApiList } from "../api/api";

export interface ChaptersState {
  chapters: { [key: number]: ApiChapterObject },
  isFetching: boolean;
  error: any;
};

// Action creators
export const storeChapters = createAction<ApiChapterObject[]>('storeChapters');
export const filterChapters = createAction<number>('filterChapters');
export const setFetchingState = createAction<boolean>('setFetchingState');
export const setErrors = createAction<any>('setErrors', null, () => ({ isError: true }));

// Async action creators
export function fetchChapters(request: ApiResponse<ApiList<ApiChapterObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => dispatch(storeChapters(res.data)))
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

export function updateChapter(request: ApiResponse<ApiChapterObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => dispatch(storeChapters([res])))
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

const chaptersReducer = createReducer<ChaptersState>({
  chapters: {},
  isFetching: false,
  error: null,
}, {
  [storeChapters.toString()]: (state, action) => {
    // state.chapters = action.payload;
    action.payload.forEach((chapter: ApiChapterObject) => {
      state.chapters[chapter.id] = chapter;
    });
  },
  [filterChapters.toString()]: (state, action) => {
    state.chapters = pickBy<ApiChapterObject>(state.chapters, (v: any, k: string) => {
      return parseInt(k) !== action.payload;
    });
  },
  [setFetchingState.toString()]: (state, action) => {
    state.isFetching = action.payload;
  },
});

export default chaptersReducer;
