import { createAction, createReducer } from "redux-starter-kit";
import { Dispatch } from "redux";
import { ApiAccountObject } from "../api/admin/accounts";
import { ApiResponse, ApiList } from "../api/api";
import localforage from 'localforage';

export interface AccountsState {
  accounts: ApiAccountObject[];
  minimal: Partial<ApiAccountObject>[];
  isFetching: boolean;
  error: any;
};

export const updateAccounts = createAction<ApiAccountObject[]>('UPDATE_ACCOUNTS');
export const updateAccount = createAction<ApiAccountObject>('UPDATE_ACCOUNT');
export const fetchAccounts = createAction('FETCH_ACCOUNTS');
export const deleteAccount = createAction<number>('DELETE_ACCOUNT');
export const storeMinimalAccounts = createAction<ApiAccountObject[]>('storeMinimalAccounts');
export const setAccountError = createAction<any>('setAccountError');
export const setAccountFetchingState = createAction<boolean>('setAccountFetchingState');

export function fetchMinimalAccounts(request: ApiResponse<ApiAccountObject[]>) {
  return (dispatch: Dispatch) => {
    dispatch(fetchAccounts());
    return request
      .then(res => dispatch(storeMinimalAccounts(res)))
      .catch((error: Error) => dispatch(setAccountError(error.message)))
  }
}

const accountsReducer = createReducer<AccountsState>({
  accounts: [],
  minimal: [],
  isFetching: false,
  error: undefined,
}, {
  UPDATE_ACCOUNTS: (state, action) => {
    state.accounts = action.payload;
    state.isFetching = false;
  },
  UPDATE_ACCOUNT: (state, action) => {
    const account: ApiAccountObject = action.payload;
    
    const index = state.accounts.findIndex(a => a.id === account.id);
    if (index !== -1 && account.id) {
      state.accounts[index] = account;
    } else if (account.id) {
      state.accounts.push(account);
    } else {
      throw new Error('Account should have an id');
    }

    state.isFetching = false;
  },
  DELETE_ACCOUNT: (state, action) => {
    const id = action.payload;
    state.accounts = state.accounts.filter(a => a.id !== id);
    state.isFetching = false;
  },
  FETCH_ACCOUNTS: (state) => {
    state.isFetching = true;
  },
  [storeMinimalAccounts.toString()]: (state, action) => {
    const accounts: ApiAccountObject[] = action.payload;
    state.minimal = accounts.map(o => ({
      id: o.id,
      name: o.name,
      logo: o.logo,
      website: o.website,
    }));
    localforage.setItem('minimalAccounts', accounts);
  },
  [setAccountError.toString()]: (state, action) => {
    try {
      state.error = JSON.parse(action.payload);
    } catch(e) {
      state.error = action.payload;
    }
  },
  [setAccountFetchingState.toString()]: (state, action) => {
    if (action.payload) {
      state.error = undefined;
    }
    state.isFetching = action.payload;
  },
});

export default accountsReducer;
