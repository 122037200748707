import auth, { AuthState } from "./auth";
import users, { UsersState } from "./users";
import accounts, { AccountsState } from './accounts';
import squads, { SquadsState } from './squads';
import chapters, { ChaptersState } from './chapters';
import needs, { NeedsState } from "./needs";
import actions, { ActionsState } from "./actions";

export interface ApplicationState {
  auth: AuthState;
  users: UsersState;
  accounts: AccountsState;
  squads: SquadsState;
  chapters: ChaptersState;
  needs: NeedsState;
  actions: ActionsState;
}

const rootReducer = {
  auth,
  users,
  accounts,
  squads,
  chapters,
  needs,
  actions,
};

export default rootReducer;
