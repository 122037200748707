import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { ApiUserObject } from '../../api/admin/users';
import TogglePicker from './TogglePicker';
import actions from '../../api/actions';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { getDropdownOptions } from '../../lib/getDropdownOptions';

interface StateProps {
  users: { [key: number]: ApiUserObject };
  currentUserId: number;
}

interface OwnProps {
  noValue?: string;
  dropdownPlaceholder?: string;
  label?: string;
  onChange?: (user: ApiUserObject) => void;
  userId?: number;
  [key: string]: any;
}

type Props = StateProps & OwnProps;

class UserPicker extends Component<Props> {
  static defaultProps = {
    noValue: 'Not assigned',
    dropdownPlaceholder: 'Select assignee',
    label: 'Assigned to',
  }
  userToggle?: TogglePicker | null;
  userRef?: DropdownProps | null;

  render() {
    const { users: stateUsers, currentUserId, userId, ...props } = this.props;
    
    const emptyUser = {} as unknown as ApiUserObject;
    const user = userId ? stateUsers[userId] : emptyUser;

    const userVals = Object.values(stateUsers);
    const me = userVals.find(o => o.id === currentUserId)
    const others = userVals.filter(o => o.id !== currentUserId);
    const users = me ? [me, ...others] : others;
    
    return (
      <TogglePicker
        label={this.props.label}
        displayValue={user ? user.full_name : this.props.noValue}
        onOpen={() => this.userRef && this.userRef.searchRef.focus()}
        ref={r => this.userToggle = r}
        image={(user && user.avatar) ? user.avatar : undefined}
        {...props}
      >
        <Dropdown
          placeholder={this.props.dropdownPlaceholder}
          search
          selection
          options={getDropdownOptions(users, 'full_name')}
          defaultValue={user.id}
          fluid
          selectOnBlur={false}
          clearable
          ref={r => this.userRef = r}
          onClose={() => this.userToggle && this.userToggle.hideEditor()}
          onChange={(e: any, { value }) => {
            const user = this.props.users[value as number];
            if (this.props.onChange) {
              this.props.onChange(user);
            }
          }}
        />
      </TogglePicker>
    )
  }
}

export default connect(
  (state: ApplicationState, ownProps: OwnProps) => ({
    users: state.users.users,
    currentUserId: state.auth.id,
  })
)(UserPicker);
