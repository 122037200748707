import React from 'react';
import logo from './masterboard.gif';
import { Link } from 'react-router-dom';

interface LogoProps {
  height: number;
}

export default function Logo({ height = 36, ...props }) {
  const imageHeight = 103.81615;
  const imageWidth = 200;
  const ratio = imageWidth / imageHeight;
  const width = 280;//height * ratio;

  const padding = height / 2;
  const wrapperHeight = padding + height + padding;

  return (
    <div style={{ padding, height: wrapperHeight }} {...props}>
      <Link to="/actions">
        <img style={{ width }} src={logo} alt="Roche Logo" />
      </Link>
    </div>
  )

}
