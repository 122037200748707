import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { TweenLite } from 'gsap';
import styles from './Sidepanel.module.scss';
import { Dimmer } from 'semantic-ui-react';

interface SidepanelProps {
  open?: boolean;
  position?: "left" | "right";
  width?: number;
  onClose?: () => void;
  flex?: boolean;
}

interface SidepanelState {
  isVisible: boolean;
};

const defaultWidth = 400;

export default class Sidepanel extends Component<SidepanelProps, SidepanelState> {
  overlayTween?: TweenLite;
  overlay?: HTMLDivElement | null;
  panelTween?: TweenLite;
  panel?: HTMLDivElement | null;
  isOpen: boolean;
  static Content: ({ children }: { children: React.ReactNode; }) => JSX.Element;
  static Footer: ({ children }: { children: React.ReactNode; }) => JSX.Element;

  constructor(props: SidepanelProps) { 
    super(props);

    this.state = {
      isVisible: true,
    };

    this.overlay = null;
    this.overlayTween = undefined;
    this.panel = null;
    this.panelTween = undefined;
    
    this.isOpen = false;
  }

  componentDidMount() {
    if (this.props.open) {
      this.open();
    }
  }

  open = () => {
    this.setState({
      isVisible: false,
    });
    if (this.overlay) {
      if (this.overlayTween) this.overlayTween.kill();
      this.overlayTween = TweenLite.to(this.overlay, 0.5, { opacity: 1 });
    }
    if (this.panel) {
      this.panelTween = TweenLite.to(this.panel, 0.5, { x: 0 });
    }
  }

  close = () => {
    if (this.overlay) {
      if (this.overlayTween) this.overlayTween.kill();
      this.overlayTween = TweenLite.to(this.overlay, 0.5, {
        opacity: 0,
        onComplete: () => this.setState({ isVisible: true }),
      });
    }
    if (this.panel) {
      this.panelTween = TweenLite.to(this.panel, 0.5, { x: this.getWidth() });
    }
  }

  getWidth = () => {
    const maxWidth = this.props.width;
    return Math.min(maxWidth || defaultWidth, window.innerWidth);
  }

  componentDidUpdate(prevProps: SidepanelProps) {
    const openPropChanged = this.props.open !== prevProps.open;
    if (openPropChanged && this.props.open) {
      this.open();
    } else if (openPropChanged) {
      this.close();
    }
  }

  render() {
    const { onClose, position, open } = this.props;
    const { isVisible } = this.state;

    const overlayClass = classNames({
      [styles.overlay]: true,
      [styles.overlayHidden]: isVisible,
    });
  
    const panelClass = classNames({
      [styles.panel]: true,
      [styles.panelLeft]: position === 'left',
      [styles.panelVisible]: open,
      [styles.panelFlex]: this.props.flex,
    });

    const width = this.getWidth();

    const panelStyles = {
      width,
      transform: `translateX(${width}px)`,
    };

    return (
      <>
        <div
          className={overlayClass}
          onClick={onClose}
          ref={r => this.overlay = r}
        >
          <Dimmer active></Dimmer>
        </div>
        <div className={panelClass} style={panelStyles} ref={r => this.panel = r}>
          {this.props.children}
        </div>
      </>
    );
  }
}

export function Content({ children }: { children: ReactNode }) {
  return (
    <div className={styles.panelContent}>
      {children}
    </div>
  )
}

export function Footer({ children }: { children: ReactNode }) {
  return (
    <div className={styles.panelFooter}>
      {children}
    </div>
  )
}

Sidepanel.Content = Content;
Sidepanel.Footer = Footer;
