import { ApiAccountObject } from "./admin/accounts";
import { ApiUserObject } from "./admin/users";
import { generateRest } from "./utils";
import { ApiSquadObject } from "./admin/squads";
import { ApiChapterObject } from "./admin/chapters";

export type NeedTypes = 'Item' | 'Hurdle' | 'Opportunity' | 'Question';
export type NeedScope = 'customer' | 'squad' | 'chapter' | 'personal';

export interface ApiNeedObject {
  id?: number;
  account_id?: number;
  account?: ApiAccountObject;
  accounts?: ApiAccountObject[] | number[];
  created_by?: ApiUserObject | number;
  name?: string;
  type?: NeedTypes;
  scope?: NeedScope;
  description?: string;
  time_to_respond?: number;
  days_left_to_respond?: number;
  nps?: number;
  created_at?: string;
  action_count?: { [key: string]: number};
  squad_id?: number,
  squad?: ApiSquadObject,
  chapter_id?: number,
  chapter?: ApiChapterObject,
  archived_at?: string,
}

const rest = generateRest<ApiNeedObject>('needs', ['account', 'chapter']);

export default rest;
