import admin from './admin';
import accounts from './accounts';
import needs from './needs';
import squads from './squads';
import chapters from './chapters';
import actions from './actions';
import users from './users';
import auth from './auth';
import filters from './filters';
import { get, post, patch, put, del } from './utils';

export type HttpTypes = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';

export const dateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = `${dateFormat} HH:mm:ss`;

export interface ApiResponseObject {
  status?: 'ok';
  code?: number;
  errors?: {
    [key: string]: string;
  };
}

export type ApiResponse<T> = Promise<T & ApiResponseObject>;

export interface ApiList<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev?: string;
    next?: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  }
}

const api = {
  get,
  post,
  patch,
  put,
  del,

  admin: {
    destroyAccount: admin.accounts.destroy,
    listAccounts: admin.accounts.list,
    showAccount: admin.accounts.show,
    updateAccount: admin.accounts.update,
    putAccount: admin.accounts.put,
    uploadAccountLogo: admin.accounts.uploadLogo,
    uploadAccountsImport: admin.accounts.uploadImport,
    destroySquad: admin.squads.destroy,
    listSquads: admin.squads.list,
    showSquad: admin.squads.show,
    storeSquad: admin.squads.store,
    updateSquad: admin.squads.update,
    putSquad: admin.squads.put,
    destroyChapter: admin.chapters.destroy,
    listChapters: admin.chapters.list,
    showChapter: admin.chapters.show,
    storeChapter: admin.chapters.store,
    updateChapter: admin.chapters.update,
    putChapter: admin.chapters.put,
    destroyUser: admin.users.destroy,
    listUsers: admin.users.list,
    showUser: admin.users.show,
    storeUser: admin.users.store,
    updateUser: admin.users.update,
    putUser: admin.users.put,
  },
  listAccounts: accounts.list,
  showAccount: accounts.show,
  listAccountLogs: accounts.logs,
  destroyNeed: needs.destroy,
  listNeeds: needs.list,
  listSquads: squads.list,
  listChapters: chapters.list,
  showNeed: needs.show,
  storeNeed: needs.store,
  updateNeed: needs.update,
  putNeed: needs.put,
  destroyAction: actions.destroy,
  listActions: actions.list,
  showAction: actions.show,
  storeAction: actions.store,
  updateAction: actions.update,
  putAction: actions.put,
  duplicateAction: actions.duplicate,
  listActionLogs: actions.listActionLogs,
  storeActionComment: actions.storeActionComment,
  uploadActionAttachment: actions.uploadAttachment,
  storeActionDocuments: actions.storeDocuments,
  deleteActionDocument: actions.deleteDocument,
  listUsers: users.list,
  showAuthProfile: auth.profile,
  upsertFilter: filters.upsert,
  listFilters: filters.list,
  deleteFilter: filters.delete,
};

export default api;
