import React, { Component, RefObject } from 'react';
import Sidepanel from '../Sidepanel/Sidepanel';
import { Button, Header } from 'semantic-ui-react';
import NeedForm, { NeedForm as NeedFormClass} from './NeedForm';
import { NeedScope, ApiNeedObject } from '../../api/needs';

interface OwnProps {
  open?: boolean;
  onClose?: (openActionPanel: boolean) => void;
  scope: NeedScope;
  need?: ApiNeedObject; 
}

type Props = OwnProps;

interface NeedPanelState {
  addAction: boolean;
}

export default class NeedPanel extends Component<Props, NeedPanelState> {
  form = React.createRef<NeedFormClass>();

  constructor(props: any) {
    super(props);
    this.state = {
      addAction: true,
    };
  }

  onSave = (addAction: boolean) => {
    this.setState({
      addAction,
    }, () => {
      if (this.form.current && this.form.current.submit.current) {
        this.form.current.submit.current.click();
      }
    });
  }

  render() {
    const { need } = this.props;
    return (
      <Sidepanel width={600} open={this.props.open} onClose={() => this.props.onClose && this.props.onClose(false)}>
        <Sidepanel.Content>
          <Header as="h1">{need ? 'Edit' : 'Create'} need</Header>
          <NeedForm
            ref={this.form}
            onSubmit={() => this.props.onClose && this.props.onClose(this.state.addAction)}
            scope={this.props.scope}
            need={this.props.need}
          />
        </Sidepanel.Content>
        <Sidepanel.Footer>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <Button basic onClick={() => this.props.onClose && this.props.onClose(false)}>Cancel</Button>
            {need ? (
              <Button primary onClick={() => this.onSave(false)}>Save &amp; close</Button>
            ) : (
              <div>
                <Button basic onClick={() => this.onSave(false)}>Save &amp; close</Button>
                <Button primary onClick={() => this.onSave(true)}>Create action</Button>
              </div>
            )}
          </div>
        </Sidepanel.Footer>
      </Sidepanel>
    )
  }
}
