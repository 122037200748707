import React from 'react';
import { UserRole } from './lib/roles';
import { includes } from 'lodash';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory, History } from 'history';
import { connect } from 'react-redux';
import { ApplicationState } from './reducers';
import Splash from './screens/Splash/Splash';
import Login from './screens/Login/Login';
import LoadComponent from './components/LoadComponent/LoadComponent';
import Dashboard, { MenuItem } from './components/Layout/Dashboard';
import objectToId, { mapObjectToId } from './lib/objectToId';
import { ApiRole } from './api/admin/users';

// Setup history
export const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

let unblockCallback: any;

export function blockRouter(message: string = 'have have entered some data, are you sure you want to leave the page?') {
  if (unblockCallback) {
    unblockCallback();
  }
  unblockCallback = history.block(message);
}

export function unblockRouter() {
  if (unblockCallback) {
    unblockCallback();
    unblockCallback = undefined;
  }
}

export function isRouterBlocked() {
  return !!unblockCallback;
}

export function setRouterBlock(b: boolean, message: string) {
  if (b) {
    blockRouter(message);
  } else {
    unblockRouter();
  }
}

history.listen(e => {
  unblockRouter();
})

declare global {
  interface Window {
    browserHistory: History
  }
}

window.browserHistory = history;

// Async loaded screens
const AsyncHome = () => <LoadComponent loader={() => import('./screens/Home/Home')} />;
const AsyncDebug = () => <LoadComponent loader={() => import('./screens/Debug/Debug')} />;

const AsyncUsers = () => <LoadComponent loader={() => import('./screens/Users/Users')} />;
const AsyncUserEdit = () => <LoadComponent loader={() => import('./screens/Users/UserEdit')} />;

const AsyncProfile = () => <LoadComponent loader={() => import('./screens/Profile/Profile')} />;

const AsyncAccounts = () => <LoadComponent loader={() => import('./screens/Accounts/Accounts')} />;
const AsyncAccountEdit = () => <LoadComponent loader={() => import('./screens/Accounts/AccountEdit')} />;

const AsyncSquads = () => <LoadComponent loader={() => import('./screens/Squads/Squads')} />;
const AsyncSquadEdit = () => <LoadComponent loader={() => import('./screens/Squads/SquadEdit')} />;

const AsyncChapters = () => <LoadComponent loader={() => import('./screens/Chapters/Chapters')} />;
const AsyncChapterEdit = () => <LoadComponent loader={() => import('./screens/Chapters/ChapterEdit')} />;

const AsyncNeeds = () => <LoadComponent loader={() => import('./screens/Needs/Needs')} />;

const AsyncActions = () => <LoadComponent loader={() => import('./screens/Actions/Actions')} />;

const AsyncVPSquads = () => <LoadComponent loader={() => import('./screens/ValuePropositions/Squads')} />;
const AsyncVPProposition = () => <LoadComponent loader={() => import('./screens/ValuePropositions/Proposition')} />;

function checkRole(roles: ApiRole[], role: UserRole) {
  return includes(mapObjectToId(roles), role);
}

interface RootRouterProps {
  roles: ApiRole[];
  isAuthFetching: boolean;
};

interface Menu {
  [key: string]: MenuItem[]
};

function RootRouter(props: RootRouterProps) {
  const { roles, isAuthFetching } = props;
  if (roles.length === 0) {
    // If the user is not logged in
    return (
      <Router history={history}>
        <div>
          <Splash active={isAuthFetching} />
          <Route component={Login} />
        </div>
      </Router>
    );
  }

  const routes = [
    <Route key="home" exact path="/" component={AsyncHome} />
  ];
  const menuItems: Menu = {
    'general': [
      {
        label: 'Home',
        to: '/',
      }
    ]
  };

  if (checkRole(roles, UserRole.Admin)) {
    // routes.push(<Route key="debug" path="/debug" component={AsyncDebug} />);
    routes.push(<Route exact key="users" path="/users" component={AsyncUsers} />);
    routes.push(<Route key="userEdit" path="/users/:id/edit" component={AsyncUserEdit} />);
    routes.push(<Route exact key="accounts" path="/accounts" component={AsyncAccounts} />);
    routes.push(<Route exact key="accountEdit" path="/accounts/:id/edit" component={AsyncAccountEdit} />);
    routes.push(<Route exact key="squads" path="/squads" component={AsyncSquads} />);
    routes.push(<Route exact key="squadEdit" path="/squads/:id/edit" component={AsyncSquadEdit} />);
    routes.push(<Route exact key="squadCreate" path="/squads/create" component={AsyncSquadEdit} />);
    routes.push(<Route exact key="chapters" path="/chapters" component={AsyncChapters} />);
    routes.push(<Route exact key="chapterEdit" path="/chapters/:id/edit" component={AsyncChapterEdit} />);
    routes.push(<Route exact key="chapterCreate" path="/chapters/create" component={AsyncChapterEdit} />);

    menuItems['Admin'] = [
      {
        label: 'Accounts',
        to: '/accounts',
      },
      {
        label: 'Users',
        to: '/users',
      },
      {
        label: 'Chapters',
        to: '/chapters',
      },
      {
        label: 'Squads',
        to: '/squads',
      },
      {
        label: 'Debug',
        to: '/debug',
      },
    ];
  }

  if (checkRole(roles, UserRole.SHP)) {
    routes.push(<Route key="profile" path="/profile" component={AsyncProfile} />);
    routes.push(<Route exact key="needs" path="/needs" component={AsyncNeeds} />);
    routes.push(<Route key="actions" path="/actions" component={AsyncActions} />);
    routes.push(<Route exact key="vpsquads" path="/value-propositions" component={AsyncVPSquads} />);
    routes.push(<Route key="vpsquadsprop" path="/value-propositions/:squadId" component={AsyncVPProposition} />);

    menuItems['Masterboard'] = [
      {
        label: 'Needs',
        to: '/needs'
      },
      {
        label: 'Actions',
        to: '/actions',
      },
      {
        label: 'Profile',
        to: '/profile',
      },
    ];
  }


  return (
    <Router history={history}>
      <Dashboard menuItems={menuItems}>
        {routes}
      </Dashboard>
    </Router>
  );
}

export default connect((state: ApplicationState) => ({
  roles: state.auth.roles,
  isAuthFetching: state.auth.isFetching,
}))(RootRouter);
