import { createReducer } from "redux-starter-kit";
import { createAction } from 'redux-actions';
import { Dispatch } from "redux";
import { pickBy } from "lodash";
import { ApiSquadObject } from "../api/admin/squads";
import { ApiResponse, ApiList } from "../api/api";

export interface SquadsState {
  squads: { [key: number]: ApiSquadObject },
  isFetching: boolean;
  error: any;
};

// Action creators
export const storeSquads = createAction<ApiSquadObject[]>('storeSquads');
export const filterSquads = createAction<number>('filterSquads');
export const setFetchingState = createAction<boolean>('setFetchingState');
export const setErrors = createAction<any>('setErrors', null, () => ({ isError: true }));

// Async action creators
export function fetchSquads(request: ApiResponse<ApiList<ApiSquadObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => dispatch(storeSquads(res.data)))
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

export function updateSquad(request: ApiResponse<ApiSquadObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => dispatch(storeSquads([res])))
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

const squadsReducer = createReducer<SquadsState>({
  squads: {},
  isFetching: false,
  error: null,
}, {
  [storeSquads.toString()]: (state, action) => {
    // state.squads = action.payload;
    action.payload.forEach((squad: ApiSquadObject) => {
      state.squads[squad.id] = squad;
    });
  },
  [filterSquads.toString()]: (state, action) => {
    state.squads = pickBy<ApiSquadObject>(state.squads, (v: any, k: string) => {
      return parseInt(k) !== action.payload;
    });
  },
  [setFetchingState.toString()]: (state, action) => {
    state.isFetching = action.payload;
  },
});

export default squadsReducer;
