import React from 'react';
import { Image, Popup } from 'semantic-ui-react';
import { ApiAccountObject } from '../../api/admin/accounts';

interface AccountLinkProps {
  account: Partial<ApiAccountObject>;
  nolink?: boolean;
  style?: {};
}

export default function AccountLink(props: AccountLinkProps) {
  const { account, nolink, style } = props;

  if (!account) {
    return null;
  }

  const styleProp = style || {};

  const content = account && account.logo && (
    <Popup
      trigger={<Image
        src={account.logo}
        height={30}
        alt={`${account.name} logo`}
      />}
      content={account.name}
      inverted
      size="tiny"
    /> 
  );

  if (nolink) {
    return (
      <span style={{ ...styleProp, display: 'flex', alignItems: 'center'}}>
        {content}
      </span>
    )
  }

  return (
    <a style={{ ...styleProp, display: 'flex', alignItems: 'center' }} rel="noopener noreferrer" href={nolink ? '' : account.website} target="_blank" onClick={(e) => e.stopPropagation()}>
      {content}
    </a>
  );
}
