import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { Provider } from 'react-redux';
import './typography.scss';
import Auth from './lib/Auth';
import { subscribeToStore as prefetchSubscribe } from './lib/prefetch';

ReactDOM.render(
  <Provider store={store}>
    <App />
    <SemanticToastContainer position="bottom-right" />
  </Provider>
, document.getElementById('root'));

declare global {
  interface Window {
    initGapi: () => void;
  }
}

Auth.init();
prefetchSubscribe();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
