import React, { ReactNode, MouseEvent } from 'react';
import classNames from 'classnames';
import styles from './LinkButton.module.scss';
import { Icon, SemanticICONS } from 'semantic-ui-react';

interface LinkButtonProps {
  linkIcon?: SemanticICONS,
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function LinkButton(props: LinkButtonProps) {
  const { linkIcon, children, className, ...rest } = props;
  return (
    <button {...rest} onClick={props.onClick} className={classNames(className, styles.button)}>
      <Icon name={linkIcon || 'linkify'} />
      {props.children}
    </button>
  );
}
