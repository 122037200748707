import React, { Component, ReactNode } from "react";
import {
  Menu,
  Icon,
  Input,
  Popup,
  Button,
  List,
  Dropdown
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { UserRole } from '../../lib/roles';
import classNames from "classnames";
import { ApplicationState } from "../../reducers";
import styles from "./Dashboard.module.scss";
import { logout } from "../../reducers/auth";
import { setAction, triggerActionPanel, setActionNeed } from "../../reducers/actions";
import { openNeedsPanel } from '../../reducers/needs';
import Auth from "../../lib/Auth";
import { keys, includes } from "lodash";
import Logo from "../Logo/Logo";
import ActionPanel from "../Actions/ActionPanel";
import ActionDetailPanel from "../Actions/ActionDetailPanel";
import api from "../../api/api";
import NeedPanel from "../Needs/NeedPanel";
import { NeedScope, ApiNeedObject } from "../../api/needs";
import { ApiRole } from "../../api/admin/users";
import { mapObjectToId } from "../../lib/objectToId";
import ActionModal from "../Actions/ActionModal";
import store from "../../store";
import { ApiActionObject } from "../../api/actions";

function checkRole(roles: ApiRole[], role: UserRole) {
  return includes(mapObjectToId(roles), role);
}

export interface MenuItem {
  label: string;
  to: string;
}

export interface DashboardProps {
  menuItems: { [key: string]: MenuItem[] };
  authName: string;
  roles: ApiRole[];
  authAvatar: string;
  children: ReactNode;
  history: any;
  logout: () => void;
  updateNeedsPanel: () => void;
  setAction: (obj: any) => any;
  triggerActionPanel: (obj: any) => any;
  openAction: number,
  openNeedsPanel: boolean,
}

interface DashboardState {
  actionsPanelOpen: boolean;
  actionsDetailPanelOpen: boolean;
  visible: boolean;
  isLoading: boolean;
  users: any;
  search: string;
  needPanelOpen: boolean;
  needPanelScope: NeedScope;
  editNeed?: ApiNeedObject,
  actionsModalOpen: boolean;
  newAction: number | undefined;
};

class Dashboard extends Component<DashboardProps, DashboardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      search: "",
      users: [],
      isLoading: false,
      actionsPanelOpen: false,
      actionsDetailPanelOpen: false,
      needPanelOpen: false,
      needPanelScope: 'customer',
      editNeed: undefined,
      actionsModalOpen: false,
      newAction: undefined,
    };
  }

  componentDidMount = () => {
    window.addEventListener('masterboard_edit_need', this.editNeed);
    window.addEventListener('masterboard_action_add', this.createNewAction);
  }

  componentWillUnmount = () => {
    window.removeEventListener('masterboard_edit_need', this.editNeed);
    window.removeEventListener('masterboard_action_add', this.createNewAction);
  }

  componentWillReceiveProps = (props: any) => {
    if (props.openAction > 0) {// @TODO: check this.props?
      this.setState({
        actionsPanelOpen: true,
        actionsModalOpen: true,
      });
    }
    if (props.actionPanelState) {
      this.openActionsPanel();
      // this.setState({
      //   actionsPanelOpen: true,
      // });
    }

    if (this.props.openNeedsPanel !== props.openNeedsPanel) {
      if (props.openNeedsPanel) {
        this.openNeedPanel('customer');
      } else {
        // this.onNeedPanelClose(false);
      }
    }
  }

  editNeed = (e: any) => {
    if (e.detail && e.detail.need) {
      this.setState({
        needPanelOpen: true,
        needPanelScope: 'customer',
        editNeed: e.detail.need,
      });
    }
  }

  onActionsPanelClose = () => {
    this.setState({
      actionsPanelOpen: false,
      newAction: undefined,
    });
    this.props.triggerActionPanel(false);
  }

  createNewAction = async () => {
    const prefillState = store.getState().actions.actionPrefillState;
    let need;
    if (prefillState  && prefillState.needs[0]) {
      need = prefillState.needs[0];
    }
    const userId = store.getState().auth.id;
    const res = await api.storeAction({
      subject: 'New action',
      need_id: need,
      assignee_id: userId,
    });
    this.setState({
      actionsPanelOpen: true,
      newAction: res.id,
    });
  }
  
  openActionsPanel = async () => {
    this.setState({
      actionsPanelOpen: true,
    });
  }

  onActionsDetailPanelClose = () => {
    this.props.setAction(-1);

    this.setState({
      actionsDetailPanelOpen: false,
    });
  }

  onActionsModalClose = () => {
    this.props.setAction(-1);
    this.setState({
      actionsModalOpen: false,
    });
  }

  onNeedPanelClose = (actionsPanelOpen: boolean) => {
    this.setState({
      actionsPanelOpen,
      needPanelOpen: false,
      editNeed: undefined,
    });
    this.props.updateNeedsPanel();
  }

  openNeedPanel = (scope: NeedScope) => {
    // return () => {
      this.setState({ 
        needPanelOpen: true,
        needPanelScope: scope,
        editNeed: undefined,
      });
    // }
  };

  toggleNav = () => {
    let visible = this.state.visible;
    this.setState({
      visible: !visible
    });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <div className={styles.topLeft}>
            <button className={styles.burger}>
              <Icon onClick={this.toggleNav} size="large" name="bars" />
            </button>
            <Logo className={styles.logo} />
            <div className={styles.topNav}>
              <Link className={styles.topNavItem} to="/needs">Needs</Link>
              <Link className={styles.topNavItem} to="/actions">Actions</Link>
              <Link className={styles.topNavItem} to="/value-propositions">Value Proposition</Link>
              {checkRole(this.props.roles, UserRole.Admin) && (
              <Dropdown text="Admin">
                <Dropdown.Menu>
                  <Dropdown.Item link="true">
                    <Link to="/accounts">Accounts</Link>
                  </Dropdown.Item>
                  <Dropdown.Item link="true">
                    <Link to="/users">Users</Link>
                  </Dropdown.Item>
                  <Dropdown.Item link="true">
                    <Link to="/chapters">Chapters</Link>
                  </Dropdown.Item>
                  <Dropdown.Item link="true">
                    <Link to="/squads">Squads</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>)}
            </div>
          </div>
          <div className={styles.search} />
          <div className={styles.userActions}>
            {/* <Dropdown
              floating
              icon="add"
              button
              direction="left"
              className="icon basic"
              color="white"
              onClick={this.openNeedPanel('customer')}
            /> */}
            <Popup
              trigger={
                <div className={styles.user}>
                  <img
                    style={{ cursor: "pointer" }}
                    src={this.props.authAvatar}
                    alt={this.props.authName}
                    className={styles.avatar}
                  />
                </div>
              }
              on="click"
              content={() => (
                <div className={styles.profilePopup}>
                  <Link className={styles.profileLink} to="/profile">
                    Bekijk profiel
                  </Link>

                  <Button onClick={Auth.signOut} fluid>
                    Uitloggen
                  </Button>
                </div>
              )}
              position="bottom right"
            />
          </div>
        </div>
        <div className={styles.content}>{this.props.children}</div>
        {/* <ActionPanel open={this.state.actionsPanelOpen} onClose={this.onActionsPanelClose} /> */}
        <ActionDetailPanel
          open={this.state.actionsDetailPanelOpen}
          onClose={this.onActionsDetailPanelClose}
          action={this.props.openAction}
        />
        <ActionModal
          open={this.state.actionsPanelOpen}
          onClose={this.onActionsPanelClose}
          action={this.state.newAction || this.props.openAction}
        />
        <NeedPanel
          open={this.state.needPanelOpen}
          onClose={this.onNeedPanelClose}
          scope={this.state.needPanelScope}
          need={this.state.editNeed}
        />
      </div>
    );
  }
}

function MenuItem(props: any) {
  return (
    <Link onClick={props.close} to={props.to}>
      <Menu.Item name={props.name} active={props.active} />
    </Link>
  );
}

function mapStateToProps(state: ApplicationState, ownProps: any) {
  return {
    authName: state.auth.name,
    authAvatar: state.auth.avatar,
    openAction: state.actions.openAction,
    actionPanelState: state.actions.actionPanelState,
    roles: state.auth.roles,
    openNeedsPanel: state.needs.openNeedsPanel,
  };
}

const mapDispatchToProps = {
  logout,
  setAction,
  setActionNeed,
  triggerActionPanel,
  updateNeedsPanel: openNeedsPanel,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
