import { DropdownItemProps } from "semantic-ui-react";

interface Obj {
  [key: string]: number | string;
}
export function getDropdownOptions(
  arr: any[] = [],
  text: string = 'name',
  value: string = 'id',
  id: string = value
): DropdownItemProps[] {
  return arr.map((o) => {
    if (typeof o[text] !== 'string' || o[value] === undefined || o[id] === undefined) {
      // console.warn(`Dropdown data misses one or more of these keys: ${text}, ${value} or ${id}.`, o);
      return {} as DropdownItemProps;
    }
    return {
      text: o[text],
      value: o[value],
      id: o[id],
    } as DropdownItemProps
  }).filter(o => o.text !== undefined);
}
