import store from '../store';
import { fetchMinimalAccounts, storeMinimalAccounts } from '../reducers/accounts';
import api from '../api/api';
import localforage from 'localforage';
import { ApiAccountObject } from '../api/admin/accounts';
import { fetchUserUsers, storeUsers } from '../reducers/users';
import { ApiUserObject } from '../api/admin/users';

export default function prefetch() {
  store.dispatch(fetchMinimalAccounts(api.listAccounts()));
  store.dispatch(fetchUserUsers(api.listUsers()));
}

export function restoreFromLocal() {
  localforage.getItem('minimalAccounts').then((res) => {
    const accounts = res as ApiAccountObject[];
    if (accounts && accounts.length) {
      store.dispatch(storeMinimalAccounts(res as ApiAccountObject[]));
    }
  });

  localforage.getItem('users').then((res) => {
    const users = res as { [key: number]: ApiUserObject };
    if (users) {
      store.dispatch(storeUsers(Object.values(users)));
    }
  })
}

export function subscribeToStore() {
  restoreFromLocal();
  
  let unsubscribe = store.subscribe(() => {
    if (store.getState().auth.isLoggedIn) {
      unsubscribe();
      prefetch();
    }
  });
}