import { get, post, del } from "./utils";
import { ApiResponse } from "./api";
import { NeedScope } from "./needs";

export interface ApiUserFilterObject {
  id: number,
  name: string,
  filter: {},
}

export interface FilterState {
  squads: number[],
  chapters: number[],
  needs: number[],
  assignees: number[],
  accounts: number[],
  query: string,
  archived: boolean,
  users: number[],
  need_scopes: number[],
}

export const emptyFilter: FilterState = {
  squads: [],
  chapters: [],
  needs: [],
  assignees: [],
  accounts: [],
  query: '',
  archived: false,
  users: [],
  need_scopes: [],
}

export const hasFilter = (filter: FilterState) => {
  return filter.query || filter.accounts.length || filter.assignees.length || filter.chapters.length || filter.squads.length || filter.users.length || filter.need_scopes.length || filter.needs.length ? true : false;
}

export default {
  list: () => {
    return get('filters') as ApiResponse<ApiUserFilterObject[]>;
  },
  upsert: (id: string | number, name: string, selected: {}) => {
    return post('filters', {
      id,
      name,
      filter: selected,
    }) as ApiResponse<ApiUserFilterObject[]>;
  },
  delete: (filterId: number | string) => {
    return del(`filters/${filterId}`) as ApiResponse<ApiUserFilterObject[]>;
  },
}
