import React, { Component, ChangeEvent, FormEvent } from 'react';
import { Form, DropdownProps, FormTextAreaProps, DropdownItemProps } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { ApiNeedObject, NeedScope } from '../../api/needs';
import api from '../../api/api';
import store from '../../store';
import { storeNeed } from '../../reducers/needs';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { ApiAccountObject } from '../../api/admin/accounts';
import { getDropdownOptions } from '../../lib/getDropdownOptions';
import { ApiUserObject } from '../../api/admin/users';
import { setActionNeed } from '../../reducers/actions';
import { ApiSquadObject } from '../../api/admin/squads';
import { ApiChapterObject } from '../../api/admin/chapters';
import Editor from '../Editor/Editor';

interface OwnProps {
  onNeedUpdated?: (need: ApiNeedObject) => void;
  onSubmit?: () => void;
  setActionNeed: (id: number) => void;
  need?: ApiNeedObject;
  scope: NeedScope;
}

interface StateProps {
  accounts: Partial<ApiAccountObject>[];
  authId: number;
}

type Props = StateProps & OwnProps;

type Option = { key: number, value: number, text: string };

interface NeedEditState {
  accounts: Option[];
  need: ApiNeedObject;
  squads: ApiSquadObject[];
  chapters: ApiChapterObject[];
};

const typeOptions = ['Item', 'Hurdle', 'Opportunity', 'Question'].map(o => ({
  key: o,
  value: o,
  text: o,
}));

function p(s: TemplateStringsArray) { return `needpanel-${s}`; }

export class NeedForm extends Component<Props, NeedEditState> {
  submit = React.createRef<HTMLInputElement>();

  state: NeedEditState = {
    accounts: [],
    need: {
      type: 'Item',
      name: '',
      description: '',
      time_to_respond: 0,
      scope: 'customer', // TODO: make dynamic
      accounts: [],
      squad_id: undefined,
      chapter_id: undefined,
    },
    squads: [],
    chapters: [],
  }

  componentDidMount = async () => {
    const squads = await api.listSquads();
    const chapters = await api.listChapters();
    this.setState({
      squads: squads,
      chapters: chapters,
    });
  }

  componentWillReceiveProps = (props: any) => {
    if (props.need && !this.props.need) {
      this.setState({
        need: props.need,
      });
    } else if (!props.need && this.props.need) {
      this.resetNeed();
    }
  }

  updateNeed = (data: ApiNeedObject) => {
    this.setState(state => ({
      need: { ...state.need, ...data },
    }));
  }

  handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const newNeed: Partial<ApiNeedObject> = {
      description: data.get(p`description`) as string,
      name: data.get(p`name`) as string,
      time_to_respond: 0,// parseFloat(data.get(p`time_to_respond`) as string),
      accounts: this.state.need.accounts,
      type: this.state.need.type,
      scope: this.state.need.scope,
      squad_id: this.state.need.squad_id,
      chapter_id: this.state.need.chapter_id,
      created_by: this.props.authId,
    }
    
    let promise;
    if (this.props.need && this.props.need.id) {
      promise = api.putNeed(this.props.need.id, newNeed);
    } else {
      promise = api.storeNeed(newNeed);
    }

    const result = await promise;
    window.dispatchEvent(new CustomEvent('masterboard_need_added'));
    
    toast({
      type: 'success',
      description: 'Need saved succesfully',
      time: 2500,
    });

    if (this.props.onSubmit) {
      if (result && result.id) {
        this.props.setActionNeed(result.id);
      }
      this.props.onSubmit();
    }

    this.resetNeed();
  }

  resetNeed = () => {
    this.setState({
      need: {
        description: '',
        name: '',
        time_to_respond: 0,
        accounts: [],
        type: 'Item',
        squad_id: undefined,
        chapter_id: undefined,
      },
    });
  }
  descriptionEditor: any;

  render() {
    const { need } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Dropdown
            id={p`need-type`}
            name={p`need-type`}
            fluid
            value={need.scope}
            options={getDropdownOptions([{
              id: 'customer',
              name: 'Customer',
              value: 'customer',
            },{
              id: 'chapter',
              name: 'Chapter',
              value: 'chapter',
            },{
              id: 'squad',
              name: 'Squad',
              value: 'squad',
            },{
              id: 'personal',
              name: 'Personal',
              value: 'personal',
            }])}
            required
            selection
            label="Need Type"
            onChange={(e: any, data: DropdownProps) => this.updateNeed({
              scope: data.value as NeedScope,
            })}
          />
        {/* <Form.Dropdown
          id={p`type`}
          name={p`type`}
          fluid
          value={need.type}
          options={typeOptions}
          search
          selection
          label="Type"
          onChange={(e: any, data: DropdownProps) => this.updateNeed({
            type: data.value as 'Hurdle' | 'Opportunity' | 'Question',
          })}
        /> */}
        <Form.Input
          id={p`name`}
          name={p`name`}
          label="Title"
          required
          value={need.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateNeed({
            name: e.currentTarget.value,
          })}
        />
        <Form.TextArea
          style={{ display: 'none' }}
          id={p`description`}
          name={p`description`}
          label="Description"
          value={need.description}
          onChange={(e: any, data: FormTextAreaProps) => this.updateNeed({
            description: data.value as string,
          })}
          rows={8}
        />
        <Editor
          ref={(r: any) => this.descriptionEditor = r}
          defaultValue={need ? need.description : ''}
          onBlur={() => {
            const content = JSON.stringify(this.descriptionEditor.getRaw());
            if (content !== need.description) {
              this.updateNeed({
                description: content,
              });
            }
          }}
        />
        <Form.Dropdown
          id={p`account-customer`}
          name={p`account-customer`}
          fluid
          value={need.accounts as number[]}
          options={getDropdownOptions(this.props.accounts)}
          search
          selection
          multiple
          label="Account"
          onChange={(e: any, data: DropdownProps) => this.updateNeed({
            accounts: data.value as number[],
          })}
          selectOnBlur={false}
          clearable
        />
        <Form.Dropdown
          id="action-chapters"
          label="Chapter"
          fluid
          search
          selection
          value={need.chapter_id}
          options={getDropdownOptions(this.state.chapters.map(o => ({
            id: o.id,
            value: o.id,
            name: o.name,
          })))}
          onChange={(e: any, data: DropdownProps) => this.updateNeed({
            chapter_id: data.value as number,
          })}
          selectOnBlur={false}
          clearable
        />
        <Form.Dropdown
          id="action-squads"
          label="Squad"
          fluid
          search
          selection
          value={need.squad_id}
          options={getDropdownOptions(this.state.squads.map(o => ({
            id: o.id,
            value: o.id,
            name: o.name,
          })))}
          onChange={(e: any, data: DropdownProps) => this.updateNeed({
            squad_id: data.value as number,
          })}
          selectOnBlur={false}
          clearable
        />
        {/* <Form.Input
          id={p`ttr`}
          name={p`time_to_respond`}
          label="Time to respond (days)"
          required
          type="number"
          value={need.time_to_respond}
          onChange={(e: ChangeEvent<HTMLInputElement>) => this.updateNeed({
            time_to_respond: parseInt(e.currentTarget.value),
          })}
        /> */}
        {/* @HACK: See note in ../Action/ActionsForm.tsx:~315 */}
        <input type="submit" ref={this.submit} style={{ display: 'none' }} />
      </Form>
    )
  }
}

export default connect(
  (state: ApplicationState) => ({
    accounts: state.accounts.minimal,
    authId: state.auth.id,
  }),
  {
    setActionNeed
  },
  null,
  { forwardRef: true }
)(NeedForm);
