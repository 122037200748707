import { ApiRole } from "./admin/users";
import { ApiResponse } from "./api";
import { get } from "./utils";

export interface ApiProfileObject {
  id: number;
  first_name: string;
  last_name: string;
  email_address: string;
  avatar?: string;
  roles?: ApiRole[];
  server_roles?: ApiRole[];
}


export default {
  profile: () => get('auth/profile') as ApiResponse<ApiProfileObject>,
}