import React, { Component } from 'react';
import styles from './Editor.module.scss';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';

interface Props {
  onBlur: () => void;
  defaultValue?: string;
}

interface State {
  editorState: EditorState;
}

export default class Editor extends Component<Props> {
  state: State;
  constructor(props: any) {
    super(props);
    let content = EditorState.createEmpty();
    if (this.props.defaultValue) {
      content = this.getEditorContent(this.props.defaultValue);
    }
    this.state = {
      editorState: content,
    }
  }

  getEditorContent(data: any) {
    try {
      // Try parsing EditorState from JSON.
      const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
      return editorState;
    } catch(e) {
      const editorState = EditorState.createWithContent(ContentState.createFromText(data));
      return editorState;
    }
  }

  componentWillReceiveProps(newProps: Props) {
    if (newProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        editorState: this.getEditorContent(newProps.defaultValue || ''),
      });
    }
  }

  onChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    })
  }

  getMarkdown() {
    const content = draftToMarkdown(convertToRaw(this.state.editorState.getCurrentContent()));
    return content;
  }

  getRaw() {
    const content = convertToRaw(this.state.editorState.getCurrentContent());
    return content;
  }

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <DraftEditor
          toolbarOnFocus
          editorState={this.state.editorState}
          toolbarClassName={styles.toolbar}
          editorClassName={styles.editor}
          onEditorStateChange={this.onChange}
          toolbar={{
            options: ['inline', 'list', 'link'],
            inline: { inDropdown: false },
            list: { inDropdown: true },
            image: { previewImage: false, }
          }}
          {...props}
        />
      </div>
    )
  }
}
