import { createReducer } from 'redux-starter-kit';
import { createAction } from 'redux-actions';
import { ApiActionObject } from '../api/actions';

export interface ActionsState {
  openAction?: number,
  needId?: number,
  actionPanelState?: boolean | {
    [key: string]: number,
  },
  actionPrefillState: false | {
    [key: string]: number[],
  },
};

// Action creators
export const setAction = createAction<ApiActionObject>('setAction');
export const setActionNeed = createAction('setActionNeed');
export const setActionPrefill = createAction('setActionPrefill');
export const triggerActionPanel = createAction<ApiActionObject>('triggerActionPanel');

const actionsReducer = createReducer<ActionsState>({
  openAction: undefined,
  actionPanelState: false,
  actionPrefillState: false,
  needId: undefined,
}, {
  [setAction.toString()]: (state, action) => {
    const openAction = action.payload;
    state.openAction = openAction;
  },
  [setActionNeed.toString()]: (state, need) => {
    const needId = need.payload;
    state.needId = needId;
  },
  [triggerActionPanel.toString()]: (state, action) => {
    const actionPanelState = action.payload;
    state.actionPanelState = actionPanelState;
  },
  [setActionPrefill.toString()]: (state, action) => {
    state.actionPrefillState = action.payload;
  },
});

export default actionsReducer;
