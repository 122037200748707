import React, { Component, MouseEvent } from 'react';
import { ApiUserObject } from '../../api/admin/users';
import TogglePicker from './TogglePicker';
import { DateInput } from 'semantic-ui-calendar-react';
import { Moment } from 'moment';
import { dateFormat } from '../../api/api';
import moment from 'moment';

interface OwnProps {
  noValue?: string;
  label?: string;
  onChange?: (date?: string) => void;
  date?: Moment;
  show?: boolean;
  [key: string]: any;
}

type Props = OwnProps;

class DatePicker extends Component<Props> {
  static defaultProps = {
    noValue: 'No deadline',
    label: 'Deadline',
  }
  dateToggle?: TogglePicker | null;
  dateRef?: any;
  dateTimeout?: number;

  constructor(props: Props) {
    super(props);
    this.state = {

    }
  }

  onClear = () => {
    this.dateToggle && this.dateToggle.hideEditor();
    if (this.props.onChange) {
      this.props.onChange(undefined);
    }
  }

  onChange = (e: any, data: any) => {
    this.dateToggle && this.dateToggle.hideEditor();
    if (this.props.onChange) {
      const date = moment(data.value, data.dateFormat).format(dateFormat);
      this.props.onChange(date);
    }
  }

  render() {
    const { date, ...props } = this.props;

    const formattedDate = date ? date.format('DD-MM-YYYY') : this.props.noValue;

    return (
      <TogglePicker
        label={this.props.label}
        displayValue={formattedDate}
        onOpen={() => this.dateRef && this.dateRef.inputNode.focus()}
        ref={r => this.dateToggle = r}
        icon="calendar alternate outline"
        {...props}
      >
        <DateInput
          clearable
          name="date"
          autoComplete="off"
          value={date ? date.format('DD-MM-YYYY') : ''}
          iconPosition="left"
          ref={(r: any) => this.dateRef = r}
          style={{ width: 160 }}
          dateFormat={'DD-MM-YYYY'}
          closable
          closePopup={() => {
            if (!this.dateToggle) return;
            if (this.dateTimeout) {
              clearTimeout(this.dateTimeout);
            }
            // @HACK: this is not a Node setTimeout wich returns Timeout but a browser's wich returns number;
            this.dateTimeout = setTimeout(this.dateToggle.hideEditor, 250) as unknown as number;
          }}
          onClear={this.onClear}
          onChange={this.onChange}
        />
      </TogglePicker>
    )
  }
}

export default DatePicker;
