import { createReducer, createAction, PayloadAction, Reducer } from 'redux-starter-kit';
import { ApiRole } from '../api/admin/users';
import localforage from 'localforage';

export interface AuthLoginProps {
  access_token: string;
  id_token: string;
  expires_at: number;
}

export interface UpdateProfileProps {
  id: number;
  name: string;
  email: string;
  avatar: string;
}

export interface AuthState {
  isFetching: boolean;
  isLoggedIn: boolean;
  authError: boolean | {};
  accessToken: string;
  tokenId: string;
  expiresAt: number;
  name: string;
  email: string;
  avatar: string;
  id: number,
  roles: ApiRole[];
}

export const login = createAction<AuthLoginProps>('AUTH_LOGIN');
export const updateProfile = createAction<UpdateProfileProps>('UPDATE_PROFILE');
export const updateRoles = createAction<ApiRole[]>('UPDATE_ROLES');
export const updateFetchState = createAction<boolean>('UPDATE_FETCH_STATE');
export const updateAuthError = createAction<boolean | {}>('UPDATE_AUTH_ERROR');
export const logout = createAction('AUTH_LOGOUT');

const authReducer = createReducer<AuthState>(
  {
    isFetching: true,
    isLoggedIn: false,
    authError: false,
    accessToken: '',
    tokenId: '',
    expiresAt: -1,
    name: '',
    email: '',
    avatar: '',
    id: 0,
    roles: [],
  },
  {
    AUTH_LOGIN: (state, action) => {
      const response: AuthLoginProps = action.payload;

      state.isLoggedIn = !!response.id_token;
      state.tokenId = response.id_token;
      
      if (response.access_token) {
        state.accessToken = response.access_token;
      }

      state.expiresAt = response.expires_at;
    },
    UPDATE_PROFILE: (state, action) => {
      const profile: UpdateProfileProps = action.payload;
      state.id = profile.id;
      state.name = profile.name;
      state.email = profile.email;
      state.avatar = profile.avatar;

      localforage.setItem('authProfile', action.payload);
    },
    UPDATE_ROLES: (state, action) => {
      state.roles = action.payload;
    },
    UPDATE_FETCH_STATE: (state, action) => {
      state.isFetching = action.payload;
    },
    UPDATE_AUTH_ERROR: (state, action) => {
      state.authError = action.payload;
    },
    AUTH_LOGOUT: (state) => {
      state.accessToken = '';
      state.expiresAt = -1;
      state.roles = [];
      state.isLoggedIn = false;
      state.tokenId = '';
      state.isFetching = false;
    },
  }
);

export default authReducer;
