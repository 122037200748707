import { ApiResponse, ApiList } from "./api";
import { generateRest, post, get, upload, del } from './utils';
import { ApiUserObject } from "./admin/users";
import { ApiSquadObject } from "./admin/squads";
import { ApiAccountObject } from "./admin/accounts";
import { ApiChapterObject } from "./admin/chapters";
import { ApiNeedObject } from "./needs";

// export type ActionStatus = 'Open';

export interface ApiLabelObject {
  name: string,
  slug: string,
}

export interface ApiGoogleDriveDocumentObject {
  id?: number;
  url: string;
  iconUrl: string;
  name: string;
}

export interface ApiActionObject {
  id: number;
  need_id: number;
  need?: ApiNeedObject;
  created_by: ApiUserObject;
  assignee_id?: number;
  assignee?: any; // TODO: define assignee
  squad_id?: number;
  squad?: ApiSquadObject;
  chapter_id?: number;
  chapter?: ApiChapterObject;
  subject: string;
  description?: string;
  deadline?: Date;
  status: number;
  status_name?: string;
  accounts: ApiAccountObject[] | number[];
  archived_at?: string;
  documents?: ApiGoogleDriveDocumentObject[];
  labels: ApiLabelObject[],
}

export interface ApiActionLogObject {
  id: number;
  user_id: number;
  user?: ApiUserObject;
  action: string;
  message: string;
  extra?: {
    from: number | number[];
    to: number | number[];
    content: string;
    [key: string]: any;
  };
  diff_for_humans: string,
  created_at: string,
}

const rest = generateRest<ApiActionObject>('actions');

export default {
  ...rest,
  storeActionComment: (id: number, data: { message: string }) => {
    return post(`actions/${id}/comment`, data) as ApiResponse<ApiActionLogObject>;
  },
  listActionLogs: (id: number) => {
    return get(`actions/${id}/logs`) as ApiResponse<ApiList<ApiActionLogObject>>;
  },
  uploadAttachment: (id: number, file: File) => upload(`actions/${id}/upload-attachment`, 'file', file),
  storeDocuments: (id: number, document: ApiGoogleDriveDocumentObject) => {
    return post(`actions/${id}/document`, document) as ApiResponse<ApiList<ApiGoogleDriveDocumentObject>>;
  },
  deleteDocument: (id: number, documentId: number) => {
    return del(`actions/${id}/document/${documentId}`);
  },
  duplicate: (id: number, accounts: number[]) => {
    return post(`actions/${id}/duplicate`, {
      accounts,
    }) as ApiResponse<ApiActionObject>;
  }
}
