import React, { Component } from "react";
import Loadable from "react-loadable";

export function Loading(props: any) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return null;
  }
}

interface LoadComponentProps {
  loader: any;
}

export default class LoadComponent extends Component<LoadComponentProps> {
  state: {
    hasError: boolean;
  };

  constructor(props: LoadComponentProps) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: true
    });
    // console.warn(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. refresh the page and try again.</h1>;
    }
    const Comp = Loadable({
      loader: this.props.loader,
      loading: Loading,
      timeout: 10000
    });

    return <Comp {...this.props} />;
  }
}

export function load(loader: any) {
  return Loadable({
    loader,
    loading: Loading,
    timeout: 10000
  });
}
