import React from "react";
import { Loader, Dimmer } from "semantic-ui-react";

interface SplashProps {
  active?: boolean;
}

const Splash = ({ active }: SplashProps) => (
  <Dimmer active={active} >
    <Loader />
  </Dimmer>
);

export default Splash;
